import { useSession } from '@plenny/visitor';
import { computed } from 'vue';
import { Features } from '$features';

export function useOffersPermissions() {
  const session = useSession();

  const canRequestOffers = computed(() => {
    if (Features.offers.isDisabled()) {
      return false;
    }

    if (Features.streamsoft.isEnabled()) {
      if (!session.value.is_authenticated) {
        return false;
      }

      if (!session.value.user.erp_id) {
        return false;
      }

      if (!session.value.user.b2b) {
        return false;
      }
    }

    return true;
  });

  return { canRequestOffers };
}
