import { computed } from 'vue';
import { Features } from '$features';

export function useOrdersPremissions() {

  const canPlaceOrders = computed(() => {
    if (Features.orders.isDisabled()) {
      return false;
    }

    return true;
  });

  return { canPlaceOrders };
}
