<script setup lang="ts">
  import { $delete, route, useSession } from '@plenny/visitor';
  import { type PropType } from 'vue';
  import type { Breadcrumb } from '$types';
  import { useOffersPermissions, useSettlementsPermissions } from '$storefront';

  defineOptions({
    layout: ['StoreLayout'],
    inheritAttrs: false,
  });

  defineProps({
    breadcrumbs: { type: Array as PropType<Breadcrumb[]>, required: true },
  });

  const { canRequestOffers } = useOffersPermissions();
  const { canRequestSettlements } = useSettlementsPermissions();

  const session = useSession();

  function actionLogout() {
    $delete(route('web.account.session.destroy'));
  }
</script>
<template>
  <div class="container container-narrow account-panel">
    <div class="account-panel__content">
      <div class="account-panel__greeting">
        <span>{{ $t('Cześć') }}</span>
        <span class="account-panel__firstname">{{ session.user.company || session.user.name }}!</span>
      </div>
      <div class="account-panel__options">
        <SfTile interactive :href="route('web.account.order.index')">
          <div class="account-panel__item">
            <SfIconBox />
            <span class="account-panel__title">{{ $t('Moje zamówienia') }}</span>
          </div>
        </SfTile>
        <SfTile v-if="canRequestOffers" interactive :href="route('web.account.offers.index')">
          <div class="account-panel__item">
            <SfIconEnvelopePaper />
            <span class="account-panel__title">{{ $t('Zapytania ofertowe') }}</span>
          </div>
        </SfTile>
        <SfTile v-if="canRequestSettlements" interactive :href="route('web.account.settlements.index')">
          <div class="account-panel__item">
            <SfIconCoin />
            <span class="account-panel__title">{{ $t('Rozrachunki') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.edit')">
          <div class="account-panel__item">
            <SfIconPersonGear />
            <span class="account-panel__title">{{ $t('Dane konta') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.notifications.edit')">
          <div class="account-panel__item">
            <SfIconBell />
            <span class="account-panel__title">{{ $t('Powiadomienia') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.review.index')">
          <div class="account-panel__item">
            <SfIconChat />
            <span class="account-panel__title">{{ $t('Opinie') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.wishlist.index')">
          <div class="account-panel__item">
            <SfIconHeart />
            <span class="account-panel__title">{{ $t('Ulubione') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.address.delivery.index')">
          <div class="account-panel__item">
            <SfIconGeo />
            <span class="account-panel__title">{{ $t('Adresy dostawy') }}</span>
          </div>
        </SfTile>
        <SfTile interactive :href="route('web.account.address.billing.index')">
          <div class="account-panel__item">
            <SfIconDocument />
            <span class="account-panel__title">{{ $t('Dane do faktury') }}</span>
          </div>
        </SfTile>
        <SfTile interactive v-if="$settings.FeatureLoyaltyEnabled" :href="route('web.account.loyalty.index')">
          <div class="account-panel__item">
            <SfIconCoin />
            <span class="account-panel__title">{{ $t('Punkty lojalnościowe') }}</span>
          </div>
        </SfTile>
        <SfTile interactive v-if="$settings.FeatureAffiliateEnabled" :href="route('web.account.affiliate.index')">
          <div class="account-panel__item">
            <SfIconShare />
            <span class="account-panel__title">{{ $t('Program partnerski') }}</span>
          </div>
        </SfTile>
      </div>
      <div class="account-panel__logout">
        <SfButton transparent @click="actionLogout">
          <template #before>
            <SfIconPower />
          </template>
          <template #default>
            {{ $t('Wyloguj się') }}
          </template>
        </SfButton>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-panel {
    &__content {
      margin: 60px 0;
    }

    &__greeting {
      font-size: 1.375rem;
    }

    &__firstname {
      font-weight: var(--font-weight-bold);
      margin-left: 0.375rem;
    }

    &__options {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 2.5rem;
      margin: 2.5rem 0;

      a:hover {
        transform: translateY(-2px);
      }
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.25rem;
    }

    svg {
      font-size: 2rem;
    }

    &__title {
      margin-top: 0.625rem;
      font-size: 1.25rem;
      font-weight: var(--font-weight-semi);
      text-align: center;
    }

    &__logout {
      display: flex;
      justify-content: flex-end;
      font-size: 1.375rem;

      svg {
        font-size: 1.25rem;
      }
    }
  }

  @include media.query(lg) {
    .account-panel {
      &__content {
        margin: 0 auto 3.125rem;
      }

      &__options {
        grid-template-columns: 1fr 1fr;
        gap: 1.25rem;
        margin: 1.25rem 0;
        padding: 0 1.25rem;
      }

      &__item {
        svg {
          font-size: 1.75rem;
        }

        .tile__content {
          padding: 1.5rem 0.625rem;
          height: 100%;
        }
      }

      &__title {
        font-size: 0.875rem;
        margin: 0;
      }

      &__logout {
        padding: 0 1.25rem;

        .btn {
          font-size: 1rem;
          font-weight: var(--font-weight-regular);
        }

        svg {
          font-size: 1.125rem;
        }
      }

      &__greeting {
        background-color: var(--sf-account-color-white);
        padding: 1rem 1.25rem;
      }
    }
  }
</style>
