<script lang="ts" setup>

  import { type PropType, computed } from 'vue';

  const props = defineProps({
    value: { type: [String, Number], required: true },
    negative: { type: Boolean, required: false, default: true },
    options: { type: Object as PropType<Intl.NumberFormatOptions>, required: false, default: {} },
  });

  const percentage = computed(() => {
    return new Intl.NumberFormat(__i18n_current, { style: 'percent', ...props.options });
  });

  const formatted = computed(() => {
    const numeric = Number(props.value);

    if (isNaN(numeric)) {
      throw new Error(`"${props.value}" cannot be cast to a numeric value.`);
    }

    return percentage.value.format(Math.abs(numeric) / (props.negative ? -100 : 100));
  });
</script>
<template>
  <data :value="value">
    {{ formatted }}
  </data>
</template>
