<script setup lang="ts">
  import { ref, onMounted, computed, watch } from 'vue';
  import { useSession, useVisitorForm, $get, route } from '@plenny/visitor';
  import { trans } from '@plenny/translator';
  import { Features } from '$features';

  const session = useSession();
  const { data, set } = useVisitorForm();

  const open = ref(false);
  const loading = ref(true);
  const addresses = ref([]);
  const address = ref();

  const customizable = computed(() => {
    return data.value.billing_type !== 'company';
  });

  const labels = computed(() => {
    return {
      custom: trans('Chcę otrzymać fakturę na inne dane'),
      type: trans('Zamawiam jako'),
      type_personal: trans('Osoba prywatna'),
      type_company: trans('Firma'),
      email: trans('E-mail na który wyślemy fakturę'),
      phone: trans('Telefon w sprawie faktur'),
    };
  });

  const billingAddressConfig = computed(() => {
    return {
      prefix: 'billing',
      externalCountry: 'country_code',
      labels: labels.value,
      customizable: customizable.value,
      contactable: true,
      contactRequired: false,
      typed: true,
      billable: true,
      named: true,
    };
  });

  const canChooseBillingAddress = computed(() => {
    return Features.streamsoft.isDisabled() && session.value.is_authenticated && addresses.value.length > 0;
  });

  const canFillBillingAddress = computed(() => {
    if (session.value.is_authenticated) {
      if (Features.streamsoft.isEnabled()) {
        if (session.value.user.erp_id) {
          return false;
        }
      }
    }

    return true;
  });

  function onAddressSelected(address: any) {
    set('billing_custom', address.type === 'personal');
    set('billing_type', address.type);
    set('billing_company', address.company);
    set('billing_name', address.name);
    set('billing_street', address.street);
    set('billing_building_nr', address.building_nr);
    set('billing_apartment_nr', address.apartment_nr);
    set('billing_postcode', address.postcode);
    set('billing_first_line', address.first_line);
    set('billing_second_line', address.second_line);
    set('billing_third_line', address.third_line);
    set('billing_city', address.city);
    set('billing_email', address.email);
    set('billing_phone', address.phone);
    set('billing_vat_id', address.vat_id);

    open.value = false;
  }

  function resetFormFields() {
    set('billing_custom', false);
    set('billing_company', null);
    set('billing_name', null);
    set('billing_street', null);
    set('billing_building_nr', null);
    set('billing_apartment_nr', null);
    set('billing_postcode', null);
    set('billing_first_line', null);
    set('billing_second_line', null);
    set('billing_third_line', null);
    set('billing_city', null);
    set('billing_email', null);
    set('billing_phone', null);
    set('billing_vat_id', null);
  }

  onMounted(() => {
    $get(route('api.v1.web.cart.address.billing.show')).then((res) => {
      addresses.value = res;
      loading.value = false;

      address.value = addresses.value.find((address: any) => {
        return address.default === true;
      });

      if (address.value) {
        onAddressSelected(address.value);
      }
    });
  });

  watch(() => data.value.billing_type, (value) => {
    if (address.value) {
      if (value === 'company') {
        if (address.value.type === 'company') {
          onAddressSelected(address.value);
        } else {
          resetFormFields();
        }
      } else {
        if (address.value.type === 'personal') {
          onAddressSelected(address.value);
        } else {
          resetFormFields();
        }
      }
    } else {
      resetFormFields();
    }
  });

  watch(() => data.value.billing_custom, (value) => {
    if (!value) {
      resetFormFields();
    }
  });
</script>
<template>
  <div class="billing-data-form">
    <div v-if="canChooseBillingAddress" class="billing-data-form__section-data">
      <div class="billing-data-form__addresses-picker">
        <SfButton primary @click="open = true">
          <template #before>
            <SfIconBook />
          </template>
          <template #default>
            {{ $t('Wypełnij z zapisanych adresów') }}
          </template>
        </SfButton>
        <SfModal v-model:open="open">
          <SfModalHeader v-model:open="open">{{ $t('Zapisane Adresy') }}</SfModalHeader>
          <OrderFormBillingDataAddressPicker :addresses="addresses" :loading="loading" @selected="onAddressSelected" />
        </SfModal>
      </div>
    </div>
    <SfAddressForm v-if="canFillBillingAddress" v-bind="billingAddressConfig" class="billing-data-form__form">
      <template v-if="customizable" #custom>
        <div class="billing-data-form__info">
          <SfIconInfo class="billing-data-form__icon" />
          <p>{{ $t('Dowodem zakupu w naszym sklepie jest faktura VAT. Domyślnie wystawimy ją na dane odbiorcy zamówienia.') }}</p>
        </div>
      </template>
    </SfAddressForm>
    <address v-if="!canFillBillingAddress" class="billing-data-form__address">
      <p v-if="data.billing_name || data.billing_company"><b>{{ data.billing_company || data.billing_name }}</b></p>
      <p v-if="data.billing_first_line">{{ data.billing_first_line }}</p>
      <p v-if="data.billing_second_line">{{ data.billing_second_line }}</p>
      <p v-if="data.billing_third_line">{{ data.billing_third_line }}</p>
      <p v-if="data.billing_vat_id">{{ $t('NIP:') }} {{ data.billing_vat_id }}</p>
      <p v-if="data.billing_email">{{ data.billing_email }}</p>
      <p v-if="data.billing_phone">{{ data.billing_phone }}</p>
    </address>
  </div>
</template>
<style lang="scss">
  .billing-data-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__address {
      font-style: normal;
      font-size: 0.875rem;
    }

    &__info {
      display: flex;
      color: var(--color-gray);
      font-size: 0.875rem;
      line-height: 1.25rem;
      gap: 0.875rem;
    }

    &__icon {
      font-size: 1.25rem;
      line-height: 1.25rem;
      vertical-align: middle;
    }

    &__form {
      max-width: 672px;
    }
  }
</style>
