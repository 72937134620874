<script setup>
  import { useBreakpoints } from '$storefront';

  const props = defineProps({
    breadcrumbs: { type: Array, required: false, default: [] },
    parent: { type: Object, required: false },
    category: { type: Object, required: false },
    sections: Array,
    categories: Array,
    module: Object,
    posts: Object,
  });

  const breakpoints = useBreakpoints();
</script>
<template>
  <main class="container">
    <SfBreadcrumbs :breadcrumbs="breadcrumbs" />
    <SectionRenderer :sections="sections" placement="before" />
    <div class="blog">
      <div class="blog__left">
        <CategoryBlog :categories="categories" :category="category" :parent="parent" />
        <!--TODO replaced passed props when BE returns popular posts - so far we render all posts - https://app.clickup.com/t/86949gnn9 -->
        <PopularPosts v-if="posts && breakpoints.lg" :posts="posts.data" />

        <div class="blog__newsletter">
          <Newsletter />
        </div>
      </div>
      <div class="blog__right">
        <SfHeading type="h1" class="blog__title">
          {{ category?.title || module.title }}
        </SfHeading>
        <div v-if="category?.description_short || module.description_short"
          class="blog__description" v-html="category?.description_short || module.description_short" />
        <div class="blog__articles">
          <Article v-for="post in posts.data" :post="post" />
        </div>
        <div class="blog__pagination">
          <SfPagination :pagination="posts" />
        </div>
      </div>
    </div>
    <SectionRenderer :sections="sections" placement="after" />
  </main>
</template>
<style lang="scss" scoped>
  .blog {
    display: grid;
    grid-template-columns: 320px 1fr;
    gap: 2.5rem;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      gap: 0;
    }

    &__left, &__right {
      background: var(--color-white);
      border-radius: 20px;
      padding: 2.5rem;

      @media (max-width: 992px) {
        border-radius: 0;
      }
    }

    &__right {
      @media (max-width: 992px) {
        order: -1;
        padding: 1.5rem;
      }

      @media (max-width: 576px) {
        padding: 1rem 1rem 2rem 1rem;
      }
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 3.5rem;
      align-self: start;

      @media (max-width: 992px) {
        padding: 0;
        gap: 1.5rem;
      }
    }

    &__newsletter {
      @media (max-width: 992px) {
        padding: 0 1rem 1rem 1rem;
      }
    }

    &__title {
      color: var(--primary-color);
      font-size: 1.125rem;
      text-transform: uppercase;
      margin-bottom: 2rem;
    }

    &__description {
      margin-bottom: 2rem;
    }

    &__articles {
      display: grid;
      grid-template-columns: repeat(4, minmax(200px, 270px));
      row-gap: 5rem;
      column-gap: 1rem;
      justify-content: flex-start;

      @media (max-width: 1400px) {
        grid-template-columns: repeat(3, minmax(200px, 270px));
      }

      @media (max-width: 1200px) {
        grid-template-columns: repeat(2, minmax(200px, 270px));
      }

      @media (max-width: 992px) {
        grid-template-columns: repeat(3, minmax(200px, 270px));
      }

      @media (max-width: 768px) {
        grid-template-columns: repeat(2, minmax(200px, 270px));
      }

      @media (max-width: 576px) {
        grid-template-columns: 1fr;
        row-gap: 1.5rem;
        :deep(.article-card) {
          max-width: none;
          max-height: none;
          height: auto;
        }
      }
    }

    &__pagination {
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
