import { useSession } from '@plenny/visitor';
import { computed } from 'vue';
import { Features } from '$features';

export function useSettlementsPermissions() {
  const session = useSession();

  const canRequestSettlements = computed(() => {
    if (Features.streamsoft.isDisabled()) {
      return false;
    }

    if (!session.value.is_authenticated) {
      return false;
    }

    if (!session.value.user.erp_id) {
      return false;
    }

    if (!session.value.user.b2b) {
      return false;
    }

    return true;
  });

  return { canRequestSettlements };
}
