<script lang="ts" setup>
  import type { PropType } from 'vue';
  import { useBreakpoints } from '$storefront';
  import type { Pagination } from '$types';
  import SfOfferStatus from '../../../Components/Formatters/OfferStatus/SfOfferStatus.vue';

  type Offer = {
    id: number;
    status: 'NEW' | 'PROCESSING' | 'ACCEPTED' | 'REJECTED';
    country_code: string;
    email: number;
    phone: string;
    created_at: string;
    updated_at: string;
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    offers: { type: Object as PropType<Pagination<Offer>>, required: true },
  });

  const breakpoints = useBreakpoints();
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Zapytania ofertowe') }}
  </AccountMobileNavigation>
  <SfTile large v-if="offers.data.length > 0" class="account-orders">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">{{ $t('Zapytania ofertowe') }}</h1>
    </template>

    <template #default>
      <div class="account-orders__content">
        <SfListCard v-for="offer in offers.data" :key="offer.id" class="account-orders">
          <template #icons>
            <SfOfferStatus :status="offer.status" />
          </template>
          <template #default>
            <div class="account-orders__header">
              <h2 class="account-orders__heading">
                {{ $t('Nr') }} {{ offer.id }}
              </h2>
              <p class="account-orders__date">
                <SfDateTime :value="offer.created_at" />
              </p>
            </div>
          </template>
          <template #actions>
            <SfButton :href="route('web.account.offers.show', { offer })" class="account-orders__details">
              {{ $t('Zobacz szczegóły') }}
            </SfButton>
          </template>
        </SfListCard>
      </div>
    </template>
  </SfTile>

  <SfTile v-else>
    <SfDataPlaceholder>
      {{ $t('Obecnie, nie masz zapisanych żadnych zaptań ofertowych.') }}
    </SfDataPlaceholder>
  </SfTile>
  <SfPagination :pagination="offers" />
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-orders {

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__date,
    &__count {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__price {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
    }

    &__details {
      margin-top: 1rem;
    }
  }

  @include media.query(lg) {
    .account-orders {
      row-gap: 1rem;

      &__content .list-card__actions {
        gap: 0.75rem;
      }
    }
  }
</style>
