<script setup lang="ts">
  import { ref, watch, type PropType } from 'vue';
  import { $get, route, useQuery, $refresh } from '@plenny/visitor';
  import { trans } from '@plenny/translator';

  interface Product {
    id: number,
    variant_id: number,
    photo: string | null,
    title: string,
    sku: string | null,
    reviews_count: number,
    reviews_average: number | string,
  }

  const props = defineProps({
    product: { type: Object as PropType<Product>, required: true },
    reviews: { type: Object, required: true },
    reviewAvailable: { type: Boolean, required: true },
    reviewsSummary: { type: Object, required: true },
  });

  const sorting = [
    { label: trans('Najstarsze'), value: 'date_asc' },
    { label: trans('Najnowsze'), value: 'date_desc' },
    { label: trans('Ocena: od najgorszej'), value: 'rating_asc' },
    { label: trans('Ocena: od najlepszej'), value: 'rating_desc' },
  ];

  const reviewsToShow = ref(props.reviews);
  const query = useQuery();
  const sort = ref(query.value.sort);
  const open = ref(false);

  watch(() => props.reviews, () => {
    onChangeSort();
  });

  function onChangeSort() {
    $get(route('api.v1.web.product.review.index', { product: props.product, sort: sort.value })).then((res) => {
      reviewsToShow.value = res.reviews;
    });
  }

  function onRedirect(page: number) {
    $get(route('api.v1.web.product.review.index', { product: props.product, page })).then((res) => {
      reviewsToShow.value = res.reviews;
    });
  }
</script>
<template>
  <div class="product-reviews">
    <div class="product-reviews__reviews">
      <div class="product-reviews__reviews-header" v-if="reviews.data.length > 0">
        <div class="product-reviews__stars">
          <span class="product-reviews__general-rating-value">{{ product.reviews_average }} / 5</span>
          <SfStars :count="product.reviews_count" :rating="product.reviews_average" />
        </div>
        <ul class="product-reviews__opinions-counter">
          <li v-for="(review, key) in reviewsSummary" class="product-reviews__counter-item">
            <span class="product-reviews__counter-item-star">
              {{ key }}
              <SfIconStarEmpty />
            </span>
            <div class="product-reviews__counter-progress">
              <div :style="{'width': review.percent + '%'}" class="product-reviews__counter-progress-value"></div>
            </div>
            <span class="product-reviews__counter-count">({{ review.count }})</span>
          </li>
        </ul>
      </div>
      <p v-else class="product-reviews__empty-reviews">
        {{ $t('Brak opinii') }}
      </p>
      <div class="product-reviews__rate" v-if="reviewAvailable">
        <span class="product-reviews__rate-hading">
          {{ $t('Masz ten produkt?') }}
        </span>
        <SfButton @click="open = true">
          <template #default>
            {{ $t('Dodaj opinię') }}
          </template>
          <template #after>
            <SfIconStarEmpty />
          </template>
        </SfButton>
      </div>
      <p v-else class="product-reviews__info">
        {{ $t('Tylko zarejestrowani Klienci, którzy kupili ten produkt, mogą wystawiać opinię.') }}
      </p>
    </div>
    <div class="product-reviews__opinions-wrapper" v-if="reviews.data.length > 0">
      <div class="product-reviews__sorting-wrapper">
        <SfFormSelect v-model="sort" :options="sorting" :label="$t('Sortowanie')" :placeholder="$t('Domyślne')" @change="onChangeSort" class="product-reviews__sorting" />
      </div>

      <ShopProductViewDescriptionComment v-for="review in reviewsToShow.data" :review @refreshComments="onChangeSort" />

      <SfPagination :pagination="reviewsToShow" @redirect="onRedirect" />
    </div>
  </div>
  <ReviewFormModal v-if="product" v-model:open="open" :product @reviewed="$refresh()" />
</template>
<style lang="scss">
  @use '$assets/mixins/media';

  :root {
    --shop-product-review-main-star-size: 2rem;
    --shop-product-review-main-count-font-size: 1rem;
    --shop-product-review-main-stars-gap: 0.5rem;
    --shop-product-review-general-rating-size: 1.75rem;

    --shop-product-review-gap: 2rem;
    --shop-product-review-comments-gap: 1rem;

    @include media.query(lg) {
      --shop-product-review-main-star-size: 1.25rem;
      --shop-product-review-main-count-font-size: 1rem;
      --shop-product-review-main-stars-gap: 0.125rem;
      --shop-product-review-general-rating-size: 1.25rem;
    }
  }

  .product-reviews {
    display: flex;
    flex-direction: column;
    gap: var(--shop-product-review-gap);

    &__empty-reviews {
      font-weight: var(--font-weight-semi);
    }

    &__reviews {
      display: flex;
      justify-content: space-between;
      gap: 4rem;
      align-items: center;
    }

    &__reviews-header {
      display: flex;
      gap: 4rem;
      flex-basis: 50%;
    }

    &__stars {
      --sf-stars-star-size: var(--shop-product-review-main-star-size);
      --sf-stars-font-size: var(--shop-product-review-main-count-font-size);
      --sf-stars-gap: var(--shop-product-review-main-stars-gap);
      --sf-stars-padding-y: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }

    &__general-rating-value {
      font-size: var(--shop-product-review-general-rating-size);
      font-weight: var(--font-weight-bold);
    }

    &__sorting-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .control__wrapper {
        width: auto;
      }
    }

    &__sorting {
      width: auto;
    }

    &__opinions-counter {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      row-gap: 0.25rem;
    }

    &__counter-item {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 100%;
    }

    &__counter-item-star {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      min-width: 2rem;
      font-size: 0.875rem;
      font-weight: var(--font-weight-500);
    }

    &__counter-progress-value {
      height: 100%;
      background-color: var(--primary-color);
      border-radius: 0.5rem;
    }

    &__counter-progress {
      flex-grow: 1;
      height: 0.5rem;
      border-radius: 0.5rem;
      background-color: var(--disabled-background);
    }

    &__counter-count {
      min-width: 2rem;
      text-align: right;
      font-size: 0.875rem;
      color: var(--color-secondary);
    }

    &__rate-hading {
      font-weight: var(--font-weight-semi);
    }

    &__rate {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
    }

    &__opinions-wrapper {
      display: flex;
      flex-direction: column;
      gap: var(--shop-product-review-comments-gap);
    }
  }

  @include media.query(xl) {
    .product-reviews {
      gap: 2rem;

      &__reviews {
        flex-direction: column;
        gap: 2rem;
      }

      &__reviews-header {
        flex-basis: unset;
        width: 100%;
      }
    }
  }

  @include media.query(md) {
    .product-reviews {

      &__info {
        font-size: 0.875rem;
      }

      &__opinions-wrapper {
        gap: 0.5rem;
      }

      &__counter-item-star {
        gap: 0.25rem;
        min-width: 1.75rem;
      }

      &__reviews {
        gap: 1rem;
      }

      &__reviews-header {
        gap: 1rem;
      }

      &__counter-item {
        gap: 0.375rem;
      }

      &__rate {
        gap: 0.5rem;
      }
    }
  }
</style>
