<script setup lang="ts">
  import { ref, type PropType, computed } from 'vue';
  import { $post, $patch, $delete, route, $refresh } from '@plenny/visitor';
  import { useBreakpoints } from '$storefront';
  import type { CustomerBillingAddress, Country } from '$types';
  import { trans } from '@plenny/translator';
  import { Features } from '$features';

  type AddressData = {
    apartment_nr: string | null;
    building_nr: string | null;
    city: string | null;
    company: string | null;
    country_code: string | null;
    default: boolean;
    email: string | null;
    phone: string | null;
    name: string | null;
    postcode: string | null;
    region: string | null;
    street: string | null;
  }

  type AddressInitial = {
    type: 'personal' | 'company' | null,
    country_code: string | null,
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    addresses: { type: Array as PropType<CustomerBillingAddress[]>, required: true },
    countries: { type: Array as PropType<Country[]>, required: true },
  });

  const breakpoints = useBreakpoints();
  const show = ref(false);
  const initial = ref<AddressInitial>({ type: 'company', country_code: __active_country_code });

  const canCreateBillingAddress = computed(() => Features.streamsoft.isDisabled());
  const canDeleteBillingAddress = computed(() => Features.streamsoft.isDisabled());

  function createAddress() {
    initial.value = { type: 'company', country_code: __active_country_code };
    show.value = true;
  }

  function editAddress(delivery: CustomerBillingAddress) {
    initial.value = delivery;
    show.value = true;
  }

  function destroyAddress(delivery: CustomerBillingAddress) {
    if (confirm(trans('Czy na pewno chcesz usunąć ten adres z zapisanych?'))) {
      $delete(route('web.account.address.billing.destroy', { billing: delivery })).then(() => {
        $refresh();
      });
    }
  }

  function storeAddress(data: AddressData) {
    return $post(route('web.account.address.billing.store'), data).then(() => {
      show.value = false;
      $refresh();
    });
  }

  function updateAddress(data: CustomerBillingAddress) {
    return $patch(route('web.account.address.billing.update', { billing: data.id }), data).then(() => {
      show.value = false;
      $refresh();
    });
  }

  function onSubmit(data: CustomerBillingAddress) {
    if (data.id) {
      return updateAddress(data);
    } else {
      return storeAddress(data);
    }
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Dane do faktury') }}
  </AccountMobileNavigation>
  <SfTile large class="account-billing">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">
        {{ $t('Dane do faktury') }}
      </h1>
    </template>
    <template #headerAction>
      <div class="account-billing__new">
        <SfButton v-if="canCreateBillingAddress" primary @click="createAddress()" class="account-billing__new-button">
          {{ $t('Dodaj nowy') }}
        </SfButton>
      </div>
    </template>
    <template #default>
      <div v-if="addresses.length > 0" class="account-billing__addresses">
        <SfTile class="account-billing__address" shadow v-for="address in addresses" :selected="address.default" :default="address.default">
          <template v-if="address.default" #outer>
            <span class="account-billing__address-default">{{ $t('Dane domyślne') }}</span>
          </template>
          <template #title>
            <h2 class="account-billing__address-name">{{ address.company || address.name }}</h2>
          </template>
          <template #headerAction>
            <SfButton v-if="canDeleteBillingAddress" transparent square rounded danger @click="destroyAddress(address)">
              <template #before>
                <SfIconTrash class="account-billing__icon" />
              </template>
            </SfButton>
          </template>
          <template #default>
            <div class="account-billing__address-data">
              <p v-if="address.first_line" class="account-billing__address-first-line">{{ address.first_line }}</p>
              <p v-if="address.second_line" class="account-billing__address-second-line">{{ address.second_line }}</p>
              <p v-if="address.third_line" class="account-billing__address-third-line">{{ address.third_line }}</p>
              <p v-if="address.country_code" class="account-billing__address-code">{{ address.country_code }}</p>
              <p v-if="address.vat_id" class="account-billing__address-nip">NIP: {{ address.vat_id }}</p>
              <p v-if="address.email" class="account-billing__address-email">{{ address.email }}</p>
              <p v-if="address.phone" class="account-billing__address-phone">{{ address.phone }}</p>
            </div>
          </template>
          <template #footer>
            <SfButton transparent @click="editAddress(address)">
              {{ $t('Edytuj') }}
            </SfButton>
          </template>
        </SfTile>
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Nie masz jeszcze zapisanych danych do faktury.') }}
      </SfDataPlaceholder>
    </template>
  </SfTile>
  <SfModal v-model:open="show">
    <AccountAddressBillingForm v-model:open="show" v-bind="{ initial, countries, onSubmit }" />
  </SfModal>
</template>
<style lang="scss">
  @use "$assets/mixins/media";

  .account-billing {
    &__addresses {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2rem;
    }

    &__address {
      display: flex;
      position: relative;
    }

    &__address-data {
      font-size: 0.875rem;
    }
  }

  @include media.query(xxl) {
    .account-billing .account-billing__addresses {
      grid-template-columns: 1fr;
    }
  }

  @include media.query(lg) {
    .account-billing {
      &__new {
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      &__new-button {
        margin-bottom: 1rem;
      }

      &__addresses {
        grid-template-columns: 1fr;
      }

      &__address .tile__outer {
        padding: 0.25rem 0.5rem;
      }

      &__address-name {
        font-size: 1.125rem;
      }
    }
  }
</style>
