<script lang="ts" setup>
  import { type PropType, computed } from 'vue';
  import { useBreakpoints } from '$storefront';
  import { trans } from '@plenny/translator';
  import { useQuery, $get, route } from '@plenny/visitor';
  import type { Pagination } from '$types';

  type Settlement = {
    documentType: string;
    documentSymbol: string;
    documentDate: string;
    dateOfPayment: string;
    currency: string;
    debit: number;
    isOverdue: boolean;
    download: string | null;
    settled: boolean;
  }

  defineOptions({
    layout: ['StoreLayout', 'AccountLayout'],
    inheritAttrs: false,
  });

  defineProps({
    settlements: { type: Object as PropType<Pagination<Settlement>>, required: true },
  });

  const breakpoints = useBreakpoints();
  const query = useQuery();

  const settledOptions = [
    { value: 0, label: trans('Nie') },
    { value: 1, label: trans('Tak') },
  ];

  const initial = computed(() => {
    return {
      settled: query.value.settled ?? 0,
    };
  });

  function handleFilters(data) {
    return $get(route('web.account.settlements.index', data));
  }
</script>
<template>
  <AccountMobileNavigation :href="route('web.account.show')">
    {{ $t('Moje zobowiązania') }}
  </AccountMobileNavigation>
  <SfTile large class="account-orders">
    <template #title v-if="breakpoints.lg">
      <h1 class="account__heading">{{ $t('Moje zobowiązania') }}</h1>
    </template>
    <template #default>
      <div class="filters">
        <VisitorForm ref="form" :initial="initial" @submit="handleFilters">
          <SfFormRadio :label="$t('Pokaż rozliczone:')" name="settled" :options="settledOptions" @change="$refs.form.submit()" />
        </VisitorForm>
      </div>
      <div v-if="settlements.data.length > 0" class="account-orders__content">
        <SfListCard v-for="settlement in settlements.data" :key="settlement.documentSymbol" class="account-orders">
          <template #icons>
            <span class="account-orders__count" :class="{ 'account-orders__overdue': !settlement.settled && settlement.isOverdue }">
              {{ $t('termin płatności:') }}
              <SfDateTime :value="settlement.dateOfPayment" />
            </span>
          </template>
          <template #default>
            <div class="account-orders__header">
              <h2 class="account-orders__heading">{{ settlement.documentType }} {{ settlement.documentSymbol }}</h2>
              <p class="account-orders__date">
                {{ $t('z dnia') }}
                <SfDateTime :value="settlement.documentDate" />
              </p>
            </div>
            <div class="account-orders__content">
              <span class="account-orders__price">
                <SfMoney :code="settlement.currency" :value="settlement.debit" />
              </span>
            </div>
          </template>
          <template #actions>
            <SfButton :href="settlement.download || ''" :disabled="!settlement.download" small target="_blank">
              {{ settlement.download ? $t('Pobierz dokument') : $t('Dokument niedostępny') }}
            </SfButton>
          </template>
        </SfListCard>
        <SfPagination :pagination="settlements" />
      </div>
      <SfDataPlaceholder v-else>
        {{ $t('Brak danych do wyświetlenia.') }}
      </SfDataPlaceholder>
    </template>
  </SfTile>
</template>

<style lang="scss">
  @use "$assets/mixins/media";

  .account-orders {

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.25rem;
    }

    &__header {
      display: flex;
      align-items: center;
      column-gap: 0.625rem;
    }

    &__heading {
      font-size: 1.25rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__date,
    &__count {
      font-size: 0.875rem;
      color: var(--sf-account-color-dark-grey);
    }

    &__overdue {
      font-weight: var(--font-weight-bold);
      color: var(--danger-color);
    }

    &__price {
      font-weight: var(--font-weight-bold);
      margin-top: 0.125rem;
    }

    &__details {
      margin-top: 1rem;
    }
  }

  @include media.query(lg) {
    .account-orders {
      row-gap: 1rem;

      &__content .list-card__actions {
        gap: 0.75rem;
      }
    }
  }
</style>
